import React, { useState } from 'react';
import { Gamepad2, Trophy, Users, Timer, Brain, Star, Bot } from 'lucide-react';

const LoginScreen = ({ onLogin }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const gameStats = [
    { icon: Users, value: '1000+', label: 'Players' },
    { icon: Trophy, value: '50K+', label: 'Games Played' },
    { icon: Timer, value: '24/7', label: 'Available' }
  ];

  const gameTypes = [
    {
      title: 'Memory Challenge',
      icon: Brain,
      description: 'Test your memory skills',
      color: 'from-purple-600/20 to-purple-400/5',
      textColor: 'text-purple-400'
    },
    {
      title: 'Classic Snake',
      icon: Gamepad2,
      description: 'Arcade-style entertainment',
      color: 'from-green-600/20 to-green-400/5',
      textColor: 'text-green-400'
    },
    {
      title: 'Tactical Tic-Tac-Toe',
      icon: Bot,
      description: 'Strategic gameplay',
      color: 'from-blue-600/20 to-blue-400/5',
      textColor: 'text-blue-400'
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim().length < 2) {
      setError('Name must be at least 2 characters long');
      return;
    }
    onLogin(name.trim());
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white overflow-x-hidden">
      {/* Background Animation */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(circle_500px_at_50%_50%,rgba(63,94,251,0.1),rgba(252,70,107,0))]"></div>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-[800px] bg-[conic-gradient(from_0deg,transparent_0_340deg,rgba(63,94,251,0.1)_360deg)] animate-[spin_8s_linear_infinite]"></div>
      </div>

      <div className="container mx-auto px-4 py-12 relative">
        {/* Header Section */}
        <div className="text-center mb-12">
          <div className="flex flex-col items-center mb-6">
            <img 
              src="/RiftDrift.ico"
              alt="RiftDrift" 
              className="w-20 h-20 mb-4"
            />
            <img 
              src="/RiftDrift-name.png"
              alt="RiftDrift Games" 
              className="h-16"
            />
          </div>
          <h1 className="text-4xl font-bold mb-4">Welcome to Gaming Paradise</h1>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Join thousands of players in our collection of engaging games. Challenge yourself or compete with friends!
          </p>
        </div>

        {/* Stats Section */}
        <div className="max-w-3xl mx-auto mb-12">
          <div className="grid grid-cols-3 gap-4">
            {gameStats.map((stat, index) => {
              const Icon = stat.icon;
              return (
                <div key={index} className="text-center p-4 bg-gray-800/50 backdrop-blur-lg rounded-xl border border-gray-700/50">
                  <Icon className="w-8 h-8 mx-auto mb-2 text-blue-400" />
                  <div className="text-2xl font-bold mb-1">{stat.value}</div>
                  <div className="text-gray-400">{stat.label}</div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Login Form */}
        <div className="max-w-md mx-auto mb-12">
          <div className="bg-gray-800/50 backdrop-blur-lg rounded-2xl border border-gray-700/50 p-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-gray-400 mb-2">Enter Your Name</label>
                <input
                  type="text"
                  placeholder="Your gaming nickname"
                  className="w-full p-4 bg-gray-900/50 border border-gray-700/50 rounded-xl text-white placeholder-gray-500 focus:ring-2 focus:ring-blue-500/50 focus:border-transparent"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setError('');
                  }}
                />
                {error && <p className="text-red-400 text-sm mt-2">{error}</p>}
              </div>
              
              <button
                type="submit"
                className="w-full p-4 bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-500 hover:to-purple-500 text-white rounded-xl font-medium transition-all transform hover:scale-[1.02] active:scale-[0.98]"
              >
                Start Playing
              </button>
            </form>
          </div>
        </div>

        {/* Game Types */}
        <div className="max-w-5xl mx-auto">
          <h2 className="text-2xl font-bold text-center mb-8">Available Games</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {gameTypes.map((game, index) => {
              const Icon = game.icon;
              return (
                <div key={index} className={`bg-gradient-to-br ${game.color} rounded-xl p-6 border border-gray-700/50 backdrop-blur-lg`}>
                  <div className={`w-12 h-12 rounded-xl bg-gray-800/50 flex items-center justify-center ${game.textColor} mb-4`}>
                    <Icon className="w-6 h-6" />
                  </div>
                  <h3 className="text-lg font-bold mb-2">{game.title}</h3>
                  <p className="text-gray-400 text-sm">{game.description}</p>
                  <div className="flex items-center mt-4 text-yellow-400">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star key={star} className="w-4 h-4" fill="currentColor" />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;