import React, { useState, useEffect, useCallback } from 'react';
import { Play, RotateCcw, Trophy } from 'lucide-react';

const CELL_SIZE = 16;
const GRID_SIZE = 20;
const INITIAL_SPEED = 150;

const SnakeGame = () => {
  const [snake, setSnake] = useState([[0, 0]]);
  const [food, setFood] = useState([5, 5]);
  const [direction, setDirection] = useState('right');
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const generateFood = useCallback(() => {
    const newFood = [
      Math.floor(Math.random() * GRID_SIZE),
      Math.floor(Math.random() * GRID_SIZE)
    ];
    if (snake.some(segment => segment[0] === newFood[0] && segment[1] === newFood[1])) {
      return generateFood();
    }
    return newFood;
  }, [snake]);

  const resetGame = () => {
    setSnake([[0, 0]]);
    setFood(generateFood());
    setDirection('right');
    setGameOver(false);
    setScore(0);
    setIsPlaying(false);
  };

  const moveSnake = useCallback(() => {
    if (gameOver) return;

    const head = snake[0];
    let newHead;

    switch (direction) {
      case 'up':
        newHead = [head[0], head[1] - 1];
        break;
      case 'down':
        newHead = [head[0], head[1] + 1];
        break;
      case 'left':
        newHead = [head[0] - 1, head[1]];
        break;
      case 'right':
        newHead = [head[0] + 1, head[1]];
        break;
      default:
        return;
    }

    if (
      newHead[0] < 0 ||
      newHead[0] >= GRID_SIZE ||
      newHead[1] < 0 ||
      newHead[1] >= GRID_SIZE ||
      snake.some(segment => segment[0] === newHead[0] && segment[1] === newHead[1])
    ) {
      setGameOver(true);
      return;
    }

    const newSnake = [newHead, ...snake];

    if (newHead[0] === food[0] && newHead[1] === food[1]) {
      setFood(generateFood());
      setScore(score + 1);
    } else {
      newSnake.pop();
    }

    setSnake(newSnake);
  }, [snake, direction, food, gameOver, generateFood, score]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!isPlaying) return;

      switch (e.key) {
        case 'ArrowUp':
          if (direction !== 'down') setDirection('up');
          break;
        case 'ArrowDown':
          if (direction !== 'up') setDirection('down');
          break;
        case 'ArrowLeft':
          if (direction !== 'right') setDirection('left');
          break;
        case 'ArrowRight':
          if (direction !== 'left') setDirection('right');
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [direction, isPlaying]);

  useEffect(() => {
    if (!isPlaying || gameOver) return;

    const gameLoop = setInterval(moveSnake, INITIAL_SPEED);
    return () => clearInterval(gameLoop);
  }, [isPlaying, gameOver, moveSnake]);

  return (
    <div className="flex flex-col h-full w-full max-w-2xl mx-auto bg-gray-800/50 backdrop-blur-lg rounded-xl border border-gray-700/50 p-4 md:p-6">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-4">
        <div className="text-xl md:text-2xl font-bold text-white">Score: {score}</div>
        <button
          onClick={resetGame}
          className="flex items-center space-x-2 bg-blue-500/20 text-blue-400 px-3 py-1.5 rounded-lg hover:bg-blue-500/30 transition-colors"
        >
          <RotateCcw className="w-4 h-4" />
          <span>Reset</span>
        </button>
      </div>

      {/* Game Board - Centered and Responsive */}
      <div className="flex-1 flex items-center justify-center min-h-0">
        <div className="relative aspect-square max-h-[90%] max-w-[90%]"
          style={{
            width: GRID_SIZE * CELL_SIZE,
            height: GRID_SIZE * CELL_SIZE
          }}>
          <div className="absolute inset-0 bg-gray-900/50 border-2 border-gray-700/50 rounded-lg">
            {snake.map((segment, index) => (
              <div
                key={index}
                className={`absolute rounded-sm transition-all duration-100 ${index === 0 ? 'bg-green-400' : 'bg-green-500'
                  }`}
                style={{
                  width: CELL_SIZE - 2,
                  height: CELL_SIZE - 2,
                  left: segment[0] * CELL_SIZE,
                  top: segment[1] * CELL_SIZE,
                }}
              />
            ))}
            <div
              className="absolute bg-red-400 rounded-full transition-all duration-200"
              style={{
                width: CELL_SIZE - 2,
                height: CELL_SIZE - 2,
                left: food[0] * CELL_SIZE,
                top: food[1] * CELL_SIZE
              }}
            />
          </div>
        </div>
      </div>

      {/* Controls Section */}
      <div className="mt-4">
        {!isPlaying && !gameOver && (
          <button
            onClick={() => setIsPlaying(true)}
            className="w-full flex items-center justify-center space-x-2 bg-green-500/20 text-green-400 p-3 rounded-lg hover:bg-green-500/30 transition-colors"
          >
            <Play className="w-5 h-5" />
            <span>Start Game</span>
          </button>
        )}

        {gameOver && (
          <div className="text-center bg-red-500/20 rounded-xl p-4">
            <div className="flex items-center justify-center space-x-2 text-red-400 mb-2">
              <Trophy className="w-6 h-6" />
              <span className="text-xl font-bold">Game Over!</span>
            </div>
            <p className="text-gray-300">Final Score: {score}</p>
          </div>
        )}

        <div className="mt-4 text-center text-gray-400 text-sm">
          Use arrow keys to control the snake
        </div>
      </div>
    </div>
  );
};

export default SnakeGame;