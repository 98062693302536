import React, { useState, useEffect, useRef } from 'react';

const PianoTiles = () => {
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [tiles, setTiles] = useState([]);
  const [speed, setSpeed] = useState(3);
  const gameRef = useRef(null);
  const animationFrameRef = useRef(null);
  const lastTimeRef = useRef(0);

  const LANE_COUNT = 4;
  const TILE_HEIGHT = 100;

  useEffect(() => {
    const initGame = () => {
      setTiles([createNewTile()]);
      setScore(0);
      setGameOver(false);
      lastTimeRef.current = performance.now();
      animate();
    };

    initGame();
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, []);

  const createNewTile = () => {
    return {
      lane: Math.floor(Math.random() * LANE_COUNT),
      y: -TILE_HEIGHT,
      id: Math.random(),
    };
  };

  const animate = (currentTime) => {
    if (gameOver) return;

    const deltaTime = (currentTime - lastTimeRef.current) / 16; // normalize to ~60fps
    lastTimeRef.current = currentTime;

    // Update tiles positions
    setTiles(prevTiles => {
      const newTiles = prevTiles.map(tile => ({
        ...tile,
        y: tile.y + speed * deltaTime,
      }));

      // Remove tiles that are off screen
      const filteredTiles = newTiles.filter(tile => tile.y < window.innerHeight);

      // Check for missed tiles
      if (newTiles.length !== filteredTiles.length) {
        setGameOver(true);
        return prevTiles;
      }

      // Add new tile if needed
      if (filteredTiles.length < 4) {
        const lastTile = filteredTiles[filteredTiles.length - 1];
        if (lastTile && lastTile.y > 0) {
          filteredTiles.push(createNewTile());
        }
      }

      return filteredTiles;
    });

    animationFrameRef.current = requestAnimationFrame(animate);
  };

  const handleTileClick = (clickedTile) => {
    if (gameOver) return;

    if (clickedTile.y > window.innerHeight - TILE_HEIGHT) {
      setGameOver(true);
      return;
    }

    setTiles(prevTiles => prevTiles.filter(tile => tile.id !== clickedTile.id));
    setScore(prev => prev + 1);
    
    // Increase speed every 10 points
    if (score > 0 && score % 10 === 0) {
      setSpeed(prev => prev + 0.5);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Piano Tiles</h2>
      
      <div className="text-white text-xl mb-4">
        Score: {score}
      </div>

      <div 
        ref={gameRef}
        className="relative w-[400px] h-[600px] bg-gray-900 overflow-hidden"
      >
        {/* Lanes */}
        <div className="absolute inset-0 grid grid-cols-4 gap-1">
          {[...Array(LANE_COUNT)].map((_, index) => (
            <div key={index} className="border-l border-gray-700" />
          ))}
        </div>

        {/* Tiles */}
        {tiles.map(tile => (
          <div
            key={tile.id}
            onClick={() => handleTileClick(tile)}
            style={{
              left: `${(tile.lane * 25)}%`,
              top: `${tile.y}px`,
            }}
            className="absolute w-[25%] h-[100px] bg-violet-500 cursor-pointer 
                     hover:bg-violet-400 transition-colors"
          />
        ))}
      </div>

      {gameOver && (
        <div className="text-center">
          <div className="text-2xl text-white mb-4">
            Game Over! Final Score: {score}
          </div>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-3 bg-violet-500 text-white rounded-lg hover:bg-violet-600"
          >
            Play Again
          </button>
        </div>
      )}
    </div>
  );
};

export default PianoTiles; 