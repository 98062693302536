import React, { useState, useEffect } from 'react';

const Puzzle2048 = () => {
  const [board, setBoard] = useState([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [won, setWon] = useState(false);

  const GRID_SIZE = 4;
  const CELL_COLORS = {
    2: 'bg-teal-200',
    4: 'bg-teal-300',
    8: 'bg-teal-400',
    16: 'bg-teal-500',
    32: 'bg-teal-600',
    64: 'bg-teal-700',
    128: 'bg-yellow-400',
    256: 'bg-yellow-500',
    512: 'bg-yellow-600',
    1024: 'bg-orange-500',
    2048: 'bg-orange-600',
  };

  const initializeBoard = () => {
    const newBoard = Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill(0));
    addNewTile(addNewTile(newBoard));
    return newBoard;
  };

  useEffect(() => {
    setBoard(initializeBoard());
    const handleKeyDown = (e) => {
      if (!gameOver) {
        switch (e.key) {
          case 'ArrowUp': moveUp(); break;
          case 'ArrowDown': moveDown(); break;
          case 'ArrowLeft': moveLeft(); break;
          case 'ArrowRight': moveRight(); break;
          default: break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const addNewTile = (currentBoard) => {
    const emptyTiles = [];
    for (let i = 0; i < GRID_SIZE; i++) {
      for (let j = 0; j < GRID_SIZE; j++) {
        if (currentBoard[i][j] === 0) {
          emptyTiles.push({ x: i, y: j });
        }
      }
    }

    if (emptyTiles.length > 0) {
      const { x, y } = emptyTiles[Math.floor(Math.random() * emptyTiles.length)];
      currentBoard[x][y] = Math.random() < 0.9 ? 2 : 4;
    }

    return currentBoard;
  };

  const compress = (board) => {
    const newBoard = Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill(0));
    let score = 0;

    for (let i = 0; i < GRID_SIZE; i++) {
      let colIndex = 0;
      for (let j = 0; j < GRID_SIZE; j++) {
        if (board[i][j] !== 0) {
          if (newBoard[i][colIndex] === 0) {
            newBoard[i][colIndex] = board[i][j];
          } else if (newBoard[i][colIndex] === board[i][j]) {
            newBoard[i][colIndex] *= 2;
            score += newBoard[i][colIndex];
            colIndex++;
          } else {
            colIndex++;
            newBoard[i][colIndex] = board[i][j];
          }
        }
      }
    }

    return { newBoard, score };
  };

  const rotate = (board) => {
    const newBoard = Array(GRID_SIZE).fill().map(() => Array(GRID_SIZE).fill(0));
    for (let i = 0; i < GRID_SIZE; i++) {
      for (let j = 0; j < GRID_SIZE; j++) {
        newBoard[i][j] = board[GRID_SIZE - 1 - j][i];
      }
    }
    return newBoard;
  };

  const moveLeft = () => {
    const { newBoard, score: newScore } = compress(board);
    if (JSON.stringify(newBoard) !== JSON.stringify(board)) {
      setScore(prev => prev + newScore);
      setBoard(addNewTile([...newBoard]));
      checkGameStatus(newBoard);
    }
  };

  const moveRight = () => {
    const rotated = rotate(rotate(board));
    const { newBoard, score: newScore } = compress(rotated);
    const finalBoard = rotate(rotate(newBoard));
    if (JSON.stringify(finalBoard) !== JSON.stringify(board)) {
      setScore(prev => prev + newScore);
      setBoard(addNewTile([...finalBoard]));
      checkGameStatus(finalBoard);
    }
  };

  const moveUp = () => {
    const rotated = rotate(rotate(rotate(board)));
    const { newBoard, score: newScore } = compress(rotated);
    const finalBoard = rotate(newBoard);
    if (JSON.stringify(finalBoard) !== JSON.stringify(board)) {
      setScore(prev => prev + newScore);
      setBoard(addNewTile([...finalBoard]));
      checkGameStatus(finalBoard);
    }
  };

  const moveDown = () => {
    const rotated = rotate(board);
    const { newBoard, score: newScore } = compress(rotated);
    const finalBoard = rotate(rotate(rotate(newBoard)));
    if (JSON.stringify(finalBoard) !== JSON.stringify(board)) {
      setScore(prev => prev + newScore);
      setBoard(addNewTile([...finalBoard]));
      checkGameStatus(finalBoard);
    }
  };

  const checkGameStatus = (currentBoard) => {
    // Check for 2048 tile
    for (let i = 0; i < GRID_SIZE; i++) {
      for (let j = 0; j < GRID_SIZE; j++) {
        if (currentBoard[i][j] === 2048) {
          setWon(true);
          return;
        }
      }
    }

    // Check for available moves
    const hasEmptyTile = currentBoard.some(row => row.some(cell => cell === 0));
    if (!hasEmptyTile) {
      // Check for possible merges
      let canMerge = false;
      for (let i = 0; i < GRID_SIZE; i++) {
        for (let j = 0; j < GRID_SIZE - 1; j++) {
          if (currentBoard[i][j] === currentBoard[i][j + 1] ||
              currentBoard[j][i] === currentBoard[j + 1][i]) {
            canMerge = true;
            break;
          }
        }
      }
      if (!canMerge) setGameOver(true);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">2048</h2>
      
      <div className="text-white text-xl mb-4">
        Score: {score}
      </div>

      <div className="bg-gray-700 p-4 rounded-xl">
        {board.map((row, i) => (
          <div key={i} className="flex">
            {row.map((cell, j) => (
              <div
                key={`${i}-${j}`}
                className={`w-16 h-16 m-1 flex items-center justify-center rounded-lg
                  ${cell === 0 ? 'bg-gray-600' : CELL_COLORS[cell]}
                  font-bold text-2xl transition-all duration-100
                  ${cell <= 4 ? 'text-gray-800' : 'text-white'}`}
              >
                {cell !== 0 && cell}
              </div>
            ))}
          </div>
        ))}
      </div>

      {(gameOver || won) && (
        <div className="text-center">
          <div className="text-2xl text-white mb-4">
            {won ? 'Congratulations! You won!' : 'Game Over!'}
            <div>Final Score: {score}</div>
          </div>
          <button
            onClick={() => {
              setBoard(initializeBoard());
              setScore(0);
              setGameOver(false);
              setWon(false);
            }}
            className="px-6 py-3 bg-teal-500 text-white rounded-lg hover:bg-teal-600"
          >
            Play Again
          </button>
        </div>
      )}
    </div>
  );
};

export default Puzzle2048; 