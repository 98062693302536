import React from 'react';
import { 
  Gamepad2, Ghost, Swords, Star, Timer, Trophy,
  Crown, Target, Rocket, Brain, Dices
} from 'lucide-react';

const games = [
  { 
    id: 'memory', 
    name: 'Memory Match', 
    icon: Gamepad2,
    color: 'text-purple-400',
    bgColor: 'bg-purple-500/20',
    hoverColor: 'hover:bg-purple-500/10'
  },
  { 
    id: 'snake', 
    name: 'Snake Evolution', 
    icon: Ghost,
    color: 'text-emerald-400',
    bgColor: 'bg-emerald-500/20',
    hoverColor: 'hover:bg-emerald-500/10'
  },
  { 
    id: 'tictactoe', 
    name: 'Tactical Tic-Tac-Toe', 
    icon: Swords,
    color: 'text-blue-400',
    bgColor: 'bg-blue-500/20',
    hoverColor: 'hover:bg-blue-500/10'
  },
  { 
    id: 'tetris', 
    name: 'Tetris Classic', 
    icon: Gamepad2,
    color: 'text-cyan-400',
    bgColor: 'bg-cyan-500/20',
    hoverColor: 'hover:bg-cyan-500/10'
  },
  { 
    id: 'pianotiles', 
    name: 'Piano Tiles', 
    icon: Star,
    color: 'text-indigo-400',
    bgColor: 'bg-indigo-500/20',
    hoverColor: 'hover:bg-indigo-500/10'
  },
  { 
    id: 'shootinggallery', 
    name: 'Shooting Gallery', 
    icon: Target,
    color: 'text-red-400',
    bgColor: 'bg-red-500/20',
    hoverColor: 'hover:bg-red-500/10'
  },
  { 
    id: '2048', 
    name: '2048 Puzzle', 
    icon: Brain,
    color: 'text-yellow-400',
    bgColor: 'bg-yellow-500/20',
    hoverColor: 'hover:bg-yellow-500/10'
  },
  { 
    id: 'chess', 
    name: 'Chess Master', 
    icon: Crown,
    color: 'text-amber-400',
    bgColor: 'bg-amber-500/20',
    hoverColor: 'hover:bg-amber-500/10'
  },
  { 
    id: 'bubblesort', 
    name: 'Bubble Sort', 
    icon: Brain,
    color: 'text-green-400',
    bgColor: 'bg-green-500/20',
    hoverColor: 'hover:bg-green-500/10'
  },
  { 
    id: 'mazerunner', 
    name: 'Maze Runner', 
    icon: Target,
    color: 'text-violet-400',
    bgColor: 'bg-violet-500/20',
    hoverColor: 'hover:bg-violet-500/10'
  },
  { 
    id: 'reactiontime', 
    name: 'Reaction Time', 
    icon: Timer,
    color: 'text-orange-400',
    bgColor: 'bg-orange-500/20',
    hoverColor: 'hover:bg-orange-500/10'
  },
  { 
    id: 'colormatch', 
    name: 'Color Match', 
    icon: Star,
    color: 'text-pink-400',
    bgColor: 'bg-pink-500/20',
    hoverColor: 'hover:bg-pink-500/10'
  },
  { 
    id: 'simonsays', 
    name: 'Simon Says', 
    icon: Brain,
    color: 'text-teal-400',
    bgColor: 'bg-teal-500/20',
    hoverColor: 'hover:bg-teal-500/10'
  },
  { 
    id: 'mathquiz', 
    name: 'Math Quiz', 
    icon: Brain,
    color: 'text-blue-400',
    bgColor: 'bg-blue-500/20',
    hoverColor: 'hover:bg-blue-500/10'
  },
  { 
    id: 'wordpuzzle', 
    name: 'Word Master', 
    icon: Brain,
    color: 'text-yellow-400',
    bgColor: 'bg-yellow-500/20',
    hoverColor: 'hover:bg-yellow-500/10'
  },
  { 
    id: 'diceroll', 
    name: 'Lucky Dice', 
    icon: Dices,
    color: 'text-pink-400',
    bgColor: 'bg-pink-500/20',
    hoverColor: 'hover:bg-pink-500/10'
  }
];

const Sidebar = ({ currentGame, setCurrentGame }) => {
  return (
    <div className="h-full bg-gray-900/80 backdrop-blur-lg border-r border-orange-500/20 overflow-hidden">
      <div className="p-4 h-full overflow-y-auto
        [&::-webkit-scrollbar]:w-1.5
        [&::-webkit-scrollbar-track]:bg-transparent
        [&::-webkit-scrollbar-thumb]:bg-gray-600/50
        [&::-webkit-scrollbar-thumb]:rounded-full
        [&::-webkit-scrollbar-thumb]:border-2
        [&::-webkit-scrollbar-thumb]:border-transparent
        [&::-webkit-scrollbar-thumb]:bg-clip-padding
        hover:[&::-webkit-scrollbar-thumb]:bg-gray-500/50">
        
        {/* Category Header */}
        <div className="mb-4 px-3 py-2">
          <h2 className="text-sm font-medium text-gray-400 uppercase tracking-wider">Game Library</h2>
        </div>

        <div className="space-y-1.5">
          {games.sort((a, b) => a.id.localeCompare(b.id)).map((game) => {
            const Icon = game.icon;
            const isActive = currentGame === game.id;
            
            return (
              <button
                key={game.id}
                onClick={() => setCurrentGame(game.id)}
                className={`w-full group relative overflow-hidden
                  px-3 py-2 rounded-lg flex items-center gap-3
                  transition-all duration-300
                  ${isActive 
                    ? 'bg-gradient-to-r from-orange-500/20 to-yellow-500/10 text-white' 
                    : 'hover:bg-gradient-to-r hover:from-orange-500/10 hover:to-yellow-500/5 text-gray-400 hover:text-white'
                  }`}
              >
                <div className={`relative p-1.5 rounded-lg 
                  transition-colors duration-300
                  ${isActive ? game.bgColor : 'bg-gray-800/50'}`}>
                  <Icon className={`w-4 h-4 ${isActive ? game.color : ''}`} />
                </div>
                <span className="font-medium">{game.name}</span>
                
                {/* Active Indicator */}
                {isActive && (
                  <div className="absolute right-0 top-0 bottom-0 w-1 
                    bg-gradient-to-b from-orange-500 via-yellow-500 to-orange-500" />
                )}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;