import React, { useState, useEffect } from 'react';

const WordPuzzle = () => {
  const [word, setWord] = useState('');
  const [guessedLetters, setGuessedLetters] = useState([]);
  const [remainingAttempts, setRemainingAttempts] = useState(6);
  const [gameStatus, setGameStatus] = useState('playing'); // 'playing', 'won', 'lost'

  const words = ['REACT', 'JAVASCRIPT', 'PROGRAMMING', 'DEVELOPER', 'CODING'];

  useEffect(() => {
    // Initialize game
    setWord(words[Math.floor(Math.random() * words.length)]);
    setGuessedLetters([]);
    setRemainingAttempts(6);
    setGameStatus('playing');
  }, []);

  const handleGuess = (letter) => {
    if (gameStatus !== 'playing') return;
    
    if (!guessedLetters.includes(letter)) {
      const newGuessedLetters = [...guessedLetters, letter];
      setGuessedLetters(newGuessedLetters);

      if (!word.includes(letter)) {
        const newAttempts = remainingAttempts - 1;
        setRemainingAttempts(newAttempts);
        
        if (newAttempts === 0) {
          setGameStatus('lost');
        }
      } else {
        // Check if won
        const isWon = word.split('').every(char => 
          newGuessedLetters.includes(char)
        );
        if (isWon) {
          setGameStatus('won');
        }
      }
    }
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Word Puzzle</h2>
      
      {/* Word Display */}
      <div className="flex space-x-2">
        {word.split('').map((letter, index) => (
          <div 
            key={index}
            className="w-10 h-10 border-2 border-gray-600 flex items-center justify-center"
          >
            <span className="text-xl text-white">
              {guessedLetters.includes(letter) ? letter : '_'}
            </span>
          </div>
        ))}
      </div>

      {/* Keyboard */}
      <div className="grid grid-cols-7 gap-2">
        {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter) => (
          <button
            key={letter}
            onClick={() => handleGuess(letter)}
            disabled={guessedLetters.includes(letter) || gameStatus !== 'playing'}
            className={`w-10 h-10 rounded-lg font-bold
              ${guessedLetters.includes(letter) 
                ? 'bg-gray-600 text-gray-400' 
                : 'bg-blue-500 text-white hover:bg-blue-600'}`}
          >
            {letter}
          </button>
        ))}
      </div>

      {/* Game Status */}
      <div className="text-white text-xl">
        {gameStatus === 'won' && 'Congratulations! You won!'}
        {gameStatus === 'lost' && `Game Over! The word was: ${word}`}
        {gameStatus === 'playing' && `Attempts remaining: ${remainingAttempts}`}
      </div>

      {/* Reset Button */}
      {gameStatus !== 'playing' && (
        <button
          onClick={() => {
            setWord(words[Math.floor(Math.random() * words.length)]);
            setGuessedLetters([]);
            setRemainingAttempts(6);
            setGameStatus('playing');
          }}
          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
        >
          Play Again
        </button>
      )}
    </div>
  );
};

export default WordPuzzle; 