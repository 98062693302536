import React, { useState, useEffect } from 'react';
import { Menu, X, Search, User, LogOut } from 'lucide-react';
import LoginScreen from './components/auth/LoginScreen';
import Sidebar from './components/layout/Sidebar';
import GameContainer from './components/layout/GameContainer';

const App = () => {
  const [playerName, setPlayerName] = useState(() => sessionStorage.getItem('playerName') || '');
  const [currentGame, setCurrentGame] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showUserMenu, setShowUserMenu] = useState(false);

  const handleSearchInput = (e) => {
    const searchValue = e.target.value;
    setSearchQuery(searchValue);
    if (currentGame && searchValue.length > 0) {
      setCurrentGame(null);
    }
  };

  const handleLogin = (name) => {
    sessionStorage.setItem('playerName', name);
    setPlayerName(name);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('playerName');
    setPlayerName('');
    setCurrentGame(null);
  };

  if (!playerName) {
    return <LoginScreen onLogin={handleLogin} />;
  }

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-800 to-black">
      {/* Enhanced Header */}
      <header className="fixed top-0 left-0 right-0 z-50 bg-gray-900/90 border-b border-orange-500/20 backdrop-blur-lg">
        <div className="absolute inset-x-0 -bottom-px h-px bg-gradient-to-r from-transparent via-orange-500/50 to-transparent" />
        <div className="h-16 px-4">
          <div className="h-full flex items-center gap-4 max-w-[2000px] mx-auto">
            {/* Enhanced Left Section */}
            <div className="flex items-center gap-3">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="lg:hidden w-10 h-10 flex items-center justify-center rounded-lg 
                  bg-gradient-to-br from-gray-800 to-gray-900 
                  hover:from-orange-500/20 hover:to-yellow-500/20 
                  text-gray-400 hover:text-white transition-all duration-300"
              >
                {isSidebarOpen ? <X className="w-5 h-5" /> : <Menu className="w-5 h-5" />}
              </button>

              <a href="/" className="flex items-center gap-3 group">
                <div className="relative">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-orange-500 to-yellow-500 rounded-full blur opacity-30 group-hover:opacity-50 transition duration-300" />
                  <img src="/RiftDrift.ico" alt="RiftDrift" className="relative w-8 h-8" />
                </div>
                <img src="/RiftDrift-name.png" alt="RiftDrift Games" className="h-10 hidden sm:block" />
              </a>
            </div>

            {/* Enhanced Search Bar */}
            <div className="flex-1 max-w-xl mx-auto">
              <div className="relative group">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-orange-500/50 via-yellow-500/50 to-orange-500/50 rounded-lg blur opacity-20 group-hover:opacity-30 transition duration-300" />
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-orange-500/70" />
                  <input
                    type="text"
                    placeholder="Search games..."
                    value={searchQuery}
                    onChange={handleSearchInput}
                    className="w-full h-10 pl-9 pr-4 bg-gray-800/50 border border-orange-500/20 rounded-lg 
                      text-gray-200 placeholder-gray-500 
                      focus:outline-none focus:ring-2 focus:ring-orange-500/50 focus:border-transparent 
                      transition-all duration-300"
                  />
                </div>
              </div>
            </div>

            {/* Enhanced User Menu */}
            <div className="relative">
              <button
                onClick={() => setShowUserMenu(!showUserMenu)}
                className="flex items-center gap-2 px-3 h-10 rounded-lg 
                  bg-gradient-to-br from-gray-800/50 to-gray-900/50 
                  hover:from-orange-500/20 hover:to-yellow-500/20 
                  text-gray-400 hover:text-white 
                  border border-orange-500/20
                  transition-all duration-300"
              >
                <User className="w-4 h-4" />
                <span className="hidden sm:block text-sm font-medium">{playerName}</span>
              </button>

              {showUserMenu && (
                <div className="absolute right-0 mt-2 w-48 py-2 
                  bg-gray-900/95 backdrop-blur-xl rounded-lg 
                  border border-orange-500/20 shadow-xl 
                  shadow-orange-500/5">
                  <button
                    onClick={handleLogout}
                    className="w-full flex items-center gap-2 px-4 py-2 
                      text-sm text-gray-400 hover:text-white 
                      hover:bg-gradient-to-r hover:from-orange-500/20 hover:to-yellow-500/20 
                      transition-all duration-300"
                  >
                    <LogOut className="w-4 h-4" />
                    <span>Logout</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Main Layout with Enhanced Styling */}
      <div className="flex pt-16">
        {/* Enhanced Mobile Sidebar */}
        <div className={`lg:hidden fixed inset-0 z-40 ${isSidebarOpen ? 'pointer-events-auto' : 'pointer-events-none'}`}>
          <div 
            className={`absolute inset-0 bg-black/80 backdrop-blur-sm transition-opacity duration-300 
              ${isSidebarOpen ? 'opacity-100' : 'opacity-0'}`}
            onClick={() => setIsSidebarOpen(false)}
          />
          
          <div className={`absolute top-16 left-0 w-64 h-[calc(100vh-4rem)] 
            bg-gray-900/95 backdrop-blur-xl 
            border-r border-orange-500/20 
            transform transition-all duration-300 ease-out 
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <Sidebar
              currentGame={currentGame}
              setCurrentGame={(game) => {
                setCurrentGame(game);
                setIsSidebarOpen(false);
              }}
            />
          </div>
        </div>

        {/* Enhanced Desktop Sidebar */}
        <aside className="hidden lg:block w-64 fixed top-16 h-[calc(100vh-4rem)] 
          border-r border-orange-500/20 
          bg-gray-900/50 backdrop-blur-lg">
          <Sidebar
            currentGame={currentGame}
            setCurrentGame={setCurrentGame}
          />
        </aside>

        {/* Main Content */}
        <main className="flex-1 lg:ml-64">
          <GameContainer 
            currentGame={currentGame} 
            searchQuery={searchQuery}
            setCurrentGame={setCurrentGame}
          />
        </main>
      </div>
    </div>
  );
};

export default App;