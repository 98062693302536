import React, { useState } from 'react';

const DiceRoll = () => {
  const [dice1, setDice1] = useState(1);
  const [dice2, setDice2] = useState(1);
  const [isRolling, setIsRolling] = useState(false);

  const rollDice = () => {
    if (isRolling) return;
    
    setIsRolling(true);
    
    // Animate dice roll
    const rollInterval = setInterval(() => {
      setDice1(Math.floor(Math.random() * 6) + 1);
      setDice2(Math.floor(Math.random() * 6) + 1);
    }, 100);

    // Stop rolling after 1 second
    setTimeout(() => {
      clearInterval(rollInterval);
      setDice1(Math.floor(Math.random() * 6) + 1);
      setDice2(Math.floor(Math.random() * 6) + 1);
      setIsRolling(false);
    }, 1000);
  };

  const renderDice = (value) => {
    return (
      <div className="w-24 h-24 bg-white rounded-xl shadow-lg flex items-center justify-center">
        <div className="grid grid-cols-3 grid-rows-3 gap-2 p-4">
          {[...Array(9)].map((_, index) => {
            const shouldShow = {
              1: [4],
              2: [0, 8],
              3: [0, 4, 8],
              4: [0, 2, 6, 8],
              5: [0, 2, 4, 6, 8],
              6: [0, 2, 3, 5, 6, 8],
            }[value]?.includes(index);

            return (
              <div
                key={index}
                className={`w-2 h-2 rounded-full ${
                  shouldShow ? 'bg-black' : 'bg-transparent'
                }`}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center space-y-8 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Lucky Dice</h2>
      
      <div className="flex space-x-8">
        {renderDice(dice1)}
        {renderDice(dice2)}
      </div>

      <div className="text-white text-xl">
        Total: {dice1 + dice2}
      </div>

      <button
        onClick={rollDice}
        disabled={isRolling}
        className={`px-6 py-3 rounded-lg font-bold text-white
          ${isRolling 
            ? 'bg-gray-600 cursor-not-allowed' 
            : 'bg-pink-500 hover:bg-pink-600'}`}
      >
        {isRolling ? 'Rolling...' : 'Roll Dice'}
      </button>
    </div>
  );
};

export default DiceRoll; 