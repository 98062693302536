import React, { useState, useEffect } from 'react';

const MazeRunner = () => {
  const [maze, setMaze] = useState([]);
  const [playerPos, setPlayerPos] = useState({ x: 1, y: 1 });
  const [gameStatus, setGameStatus] = useState('playing'); // playing, won
  const [moves, setMoves] = useState(0);
  const MAZE_SIZE = 15;

  const generateMaze = () => {
    // Initialize maze with walls
    const newMaze = Array(MAZE_SIZE).fill().map(() => 
      Array(MAZE_SIZE).fill(1)
    );

    const recursiveBacktrack = (x, y) => {
      newMaze[y][x] = 0;
      
      // Define possible directions: [dx, dy]
      const directions = [
        [0, -2], // up
        [2, 0],  // right
        [0, 2],  // down
        [-2, 0]  // left
      ];
      
      // Shuffle directions
      for (let i = directions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [directions[i], directions[j]] = [directions[j], directions[i]];
      }

      // Try each direction
      for (const [dx, dy] of directions) {
        const newX = x + dx;
        const newY = y + dy;
        
        if (newX > 0 && newX < MAZE_SIZE - 1 && 
            newY > 0 && newY < MAZE_SIZE - 1 && 
            newMaze[newY][newX] === 1) {
          // Carve passage
          newMaze[y + dy/2][x + dx/2] = 0;
          recursiveBacktrack(newX, newY);
        }
      }
    };

    // Start from top-left corner
    recursiveBacktrack(1, 1);

    // Set start and end points
    newMaze[1][1] = 'S';
    newMaze[MAZE_SIZE-2][MAZE_SIZE-2] = 'E';

    return newMaze;
  };

  useEffect(() => {
    const newMaze = generateMaze();
    setMaze(newMaze);
    setPlayerPos({ x: 1, y: 1 });
    setGameStatus('playing');
    setMoves(0);
  }, []);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (gameStatus !== 'playing') return;

      const newPos = { ...playerPos };
      
      switch (e.key) {
        case 'ArrowUp': newPos.y--; break;
        case 'ArrowDown': newPos.y++; break;
        case 'ArrowLeft': newPos.x--; break;
        case 'ArrowRight': newPos.x++; break;
        default: return;
      }

      // Check if move is valid
      if (maze[newPos.y]?.[newPos.x] !== 1) {
        setPlayerPos(newPos);
        setMoves(prev => prev + 1);
        
        // Check if reached end
        if (maze[newPos.y][newPos.x] === 'E') {
          setGameStatus('won');
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [playerPos, maze, gameStatus]);

  const getCellClass = (cell, x, y) => {
    if (x === playerPos.x && y === playerPos.y) {
      return 'bg-green-500';
    }
    switch (cell) {
      case 1: return 'bg-gray-900'; // wall
      case 'S': return 'bg-blue-500'; // start
      case 'E': return 'bg-red-500'; // end
      default: return 'bg-gray-600'; // path
    }
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Maze Runner</h2>
      
      <div className="text-white text-xl">
        Moves: {moves}
      </div>

      <div className="bg-gray-700 p-4 rounded-xl">
        {maze.map((row, y) => (
          <div key={y} className="flex">
            {row.map((cell, x) => (
              <div
                key={`${x}-${y}`}
                className={`w-8 h-8 ${getCellClass(cell, x, y)} 
                  border border-gray-800 transition-colors duration-100`}
              />
            ))}
          </div>
        ))}
      </div>

      {gameStatus === 'won' && (
        <div className="text-center">
          <div className="text-2xl text-white mb-4">
            Maze Completed! Moves: {moves}
          </div>
          <button
            onClick={() => {
              const newMaze = generateMaze();
              setMaze(newMaze);
              setPlayerPos({ x: 1, y: 1 });
              setGameStatus('playing');
              setMoves(0);
            }}
            className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600"
          >
            Play Again
          </button>
        </div>
      )}

      <div className="text-gray-300 text-sm">
        Use arrow keys to navigate through the maze
      </div>
    </div>
  );
};

export default MazeRunner; 