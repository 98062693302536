import React, { useState, useEffect } from 'react';

const Chess = () => {
  const [board, setBoard] = useState([]);
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState('white');
  const [gameStatus, setGameStatus] = useState('playing'); // playing, checkmate, stalemate

  // Initialize the chess board
  useEffect(() => {
    initializeBoard();
  }, []);

  const initializeBoard = () => {
    const newBoard = Array(8).fill().map(() => Array(8).fill(null));
    
    // Set up pawns
    for (let i = 0; i < 8; i++) {
      newBoard[1][i] = { type: 'pawn', color: 'black' };
      newBoard[6][i] = { type: 'pawn', color: 'white' };
    }

    // Set up other pieces
    const backRow = ['rook', 'knight', 'bishop', 'queen', 'king', 'bishop', 'knight', 'rook'];
    for (let i = 0; i < 8; i++) {
      newBoard[0][i] = { type: backRow[i], color: 'black' };
      newBoard[7][i] = { type: backRow[i], color: 'white' };
    }

    setBoard(newBoard);
  };

  const getPieceSymbol = (piece) => {
    if (!piece) return '';
    
    const symbols = {
      white: {
        king: '♔',
        queen: '♕',
        rook: '♖',
        bishop: '♗',
        knight: '♘',
        pawn: '♙'
      },
      black: {
        king: '♚',
        queen: '♛',
        rook: '♜',
        bishop: '♝',
        knight: '♞',
        pawn: '♟'
      }
    };

    return symbols[piece.color][piece.type];
  };

  const handleSquareClick = (row, col) => {
    if (gameStatus !== 'playing') return;

    const piece = board[row][col];

    // If no piece is selected and clicked square has a piece of current player's color
    if (!selectedPiece && piece && piece.color === currentPlayer) {
      setSelectedPiece({ row, col });
      return;
    }

    // If a piece is selected, try to move it
    if (selectedPiece) {
      if (isValidMove(selectedPiece, { row, col })) {
        movePiece(selectedPiece, { row, col });
        setSelectedPiece(null);
        setCurrentPlayer(currentPlayer === 'white' ? 'black' : 'white');
      } else {
        setSelectedPiece(null);
      }
    }
  };

  const isValidMove = (from, to) => {
    const piece = board[from.row][from.col];
    if (!piece) return false;

    // Basic move validation (can be expanded for each piece type)
    switch (piece.type) {
      case 'pawn':
        // Simple pawn movement (can be expanded for first move, captures, en passant)
        if (piece.color === 'white') {
          return to.row === from.row - 1 && to.col === from.col && !board[to.row][to.col];
        } else {
          return to.row === from.row + 1 && to.col === from.col && !board[to.row][to.col];
        }
      
      case 'rook':
        return (to.row === from.row || to.col === from.col) && !board[to.row][to.col];
      
      // Add other piece movement rules as needed
      default:
        return true; // Temporary - allow all moves for other pieces
    }
  };

  const movePiece = (from, to) => {
    const newBoard = [...board];
    newBoard[to.row][to.col] = newBoard[from.row][from.col];
    newBoard[from.row][from.col] = null;
    setBoard(newBoard);

    // Check for checkmate or stalemate
    checkGameStatus(newBoard);
  };

  const checkGameStatus = (currentBoard) => {
    // Simplified check for demo purposes
    // In a real chess game, you'd need to implement proper checkmate detection
    const kings = { white: false, black: false };
    
    currentBoard.forEach(row => {
      row.forEach(piece => {
        if (piece && piece.type === 'king') {
          kings[piece.color] = true;
        }
      });
    });

    if (!kings.white) setGameStatus('checkmate');
    if (!kings.black) setGameStatus('checkmate');
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Chess</h2>
      
      <div className="text-white text-xl mb-4">
        {gameStatus === 'playing' ? `${currentPlayer}'s turn` : 'Game Over'}
      </div>

      <div className="bg-gray-700 p-4 rounded-xl">
        {board.map((row, rowIndex) => (
          <div key={rowIndex} className="flex">
            {row.map((piece, colIndex) => (
              <div
                key={`${rowIndex}-${colIndex}`}
                onClick={() => handleSquareClick(rowIndex, colIndex)}
                className={`w-16 h-16 flex items-center justify-center text-4xl
                  ${(rowIndex + colIndex) % 2 === 0 ? 'bg-gray-300' : 'bg-gray-500'}
                  ${selectedPiece?.row === rowIndex && selectedPiece?.col === colIndex 
                    ? 'bg-blue-300' : ''}
                  cursor-pointer hover:opacity-80`}
              >
                {getPieceSymbol(piece)}
              </div>
            ))}
          </div>
        ))}
      </div>

      {gameStatus !== 'playing' && (
        <button
          onClick={initializeBoard}
          className="px-6 py-3 bg-slate-500 text-white rounded-lg hover:bg-slate-600"
        >
          New Game
        </button>
      )}
    </div>
  );
};

export default Chess; 