import React, { useState, useEffect } from 'react';
import { RotateCcw, Trophy, Users, Bot } from 'lucide-react';

const TicTacToe = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [xIsNext, setXIsNext] = useState(true);
  const [gameMode, setGameMode] = useState(null); // 'pvp' or 'ai'
  const [gameStatus, setGameStatus] = useState(null); // null, 'win', 'draw'
  const [winningLine, setWinningLine] = useState(null);

  const calculateWinner = (squares) => {
    const lines = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8], // rows
      [0, 3, 6], [1, 4, 7], [2, 5, 8], // columns
      [0, 4, 8], [2, 4, 6] // diagonals
    ];

    for (let line of lines) {
      const [a, b, c] = line;
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return { winner: squares[a], line };
      }
    }

    if (squares.every(square => square !== null)) {
      return { winner: 'draw' };
    }

    return null;
  };

  const findBestMove = (currentBoard) => {
    // Try to win
    const winningMove = findWinningMove(currentBoard, 'O');
    if (winningMove !== -1) return winningMove;

    // Block opponent from winning
    const blockingMove = findWinningMove(currentBoard, 'X');
    if (blockingMove !== -1) return blockingMove;

    // Take center if available
    if (currentBoard[4] === null) return 4;

    // Take corners
    const corners = [0, 2, 6, 8];
    const availableCorners = corners.filter(i => currentBoard[i] === null);
    if (availableCorners.length > 0) {
      return availableCorners[Math.floor(Math.random() * availableCorners.length)];
    }

    // Take any available space
    const availableSpaces = currentBoard
      .map((square, i) => square === null ? i : null)
      .filter(i => i !== null);
    return availableSpaces[Math.floor(Math.random() * availableSpaces.length)];
  };

  const findWinningMove = (currentBoard, player) => {
    const lines = [
      [0, 1, 2], [3, 4, 5], [6, 7, 8],
      [0, 3, 6], [1, 4, 7], [2, 5, 8],
      [0, 4, 8], [2, 4, 6]
    ];

    for (let line of lines) {
      const [a, b, c] = line;
      const squares = [currentBoard[a], currentBoard[b], currentBoard[c]];
      if (squares.filter(square => square === player).length === 2 &&
          squares.filter(square => square === null).length === 1) {
        return line[squares.indexOf(null)];
      }
    }
    return -1;
  };

  const handleClick = (i) => {
    if (board[i] || gameStatus || !gameMode) return;

    const newBoard = [...board];
    newBoard[i] = xIsNext ? 'X' : 'O';
    setBoard(newBoard);

    const result = calculateWinner(newBoard);
    if (result) {
      setGameStatus(result.winner === 'draw' ? 'draw' : 'win');
      setWinningLine(result.line);
      return;
    }

    setXIsNext(!xIsNext);
  };

  useEffect(() => {
    if (gameMode === 'ai' && !xIsNext && !gameStatus) {
      const timer = setTimeout(() => {
        const move = findBestMove(board);
        if (move !== -1) {
          const newBoard = [...board];
          newBoard[move] = 'O';
          setBoard(newBoard);
          
          const result = calculateWinner(newBoard);
          if (result) {
            setGameStatus(result.winner === 'draw' ? 'draw' : 'win');
            setWinningLine(result.line);
            return;
          }
          
          setXIsNext(true);
        }
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [xIsNext, gameMode, gameStatus, board]);

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setXIsNext(true);
    setGameStatus(null);
    setWinningLine(null);
  };

  const renderSquare = (i) => {
    const isWinningSquare = winningLine?.includes(i);
    return (
      <button
        key={i}
        onClick={() => handleClick(i)}
        className={`w-20 h-20 text-2xl font-bold flex items-center justify-center transition-all duration-200
          ${!board[i] && !gameStatus ? 'hover:bg-gray-700/30' : ''}
          ${isWinningSquare ? 'bg-green-500/20 border-green-500/50' : 'bg-gray-800/50 border-gray-700/50'}
          border rounded-lg
          ${board[i] === 'X' ? 'text-blue-400' : 'text-red-400'}`}
      >
        {board[i]}
      </button>
    );
  };

  if (!gameMode) {
    return (
      <div className="bg-gray-800/50 backdrop-blur-lg rounded-xl border border-gray-700/50 p-8 w-full max-w-md mx-auto">
        <h2 className="text-2xl font-bold text-white text-center mb-8">Choose Game Mode</h2>
        <div className="flex flex-col gap-4">
          <button
            onClick={() => setGameMode('pvp')}
            className="flex items-center justify-center gap-3 bg-blue-500/20 text-blue-400 p-4 rounded-lg hover:bg-blue-500/30 transition-colors"
          >
            <Users className="w-6 h-6" />
            Player vs Player
          </button>
          <button
            onClick={() => setGameMode('ai')}
            className="flex items-center justify-center gap-3 bg-purple-500/20 text-purple-400 p-4 rounded-lg hover:bg-purple-500/30 transition-colors"
          >
            <Bot className="w-6 h-6" />
            Play vs AI
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-800/50 backdrop-blur-lg rounded-xl border border-gray-700/50 p-8 w-full max-w-lg mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div className="text-xl font-semibold text-white">
          {gameMode === 'pvp' ? 'Player vs Player' : 'Player vs AI'}
        </div>
        <button
          onClick={resetGame}
          className="flex items-center space-x-2 bg-blue-500/20 text-blue-400 px-4 py-2 rounded-lg hover:bg-blue-500/30 transition-colors"
        >
          <RotateCcw className="w-4 h-4" />
          <span>Reset</span>
        </button>
      </div>

      <div className="grid grid-cols-3 gap-3 mb-6">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => renderSquare(i))}
      </div>

      {!gameStatus && (
        <div className="text-center text-lg text-gray-400">
          Next player: <span className="font-bold">{xIsNext ? 'X' : 'O'}</span>
        </div>
      )}

      {gameStatus && (
        <div className="mt-6 text-center bg-blue-500/20 rounded-xl p-6">
          <div className="flex items-center justify-center gap-2">
            <Trophy className="w-8 h-8 text-yellow-400" />
            <span className="text-2xl font-bold text-white">
              {gameStatus === 'draw' ? "It's a Draw!" : `Winner: ${!xIsNext ? 'X' : 'O'}`}
            </span>
          </div>
          <button
            onClick={() => setGameMode(null)}
            className="mt-4 text-gray-400 hover:text-white transition-colors"
          >
            Change Game Mode
          </button>
        </div>
      )}
    </div>
  );
};

export default TicTacToe;