import React, { useState, useEffect } from 'react';
import { Timer, RotateCcw, Trophy, Shuffle } from 'lucide-react';

const MemoryGame = () => {
  const [cards, setCards] = useState([]);
  const [flipped, setFlipped] = useState([]);
  const [matched, setMatched] = useState([]);
  const [moves, setMoves] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [time, setTime] = useState(0);

  const symbols = ['🎮', '🎲', '🎯', '🎪', '🎨', '🎭', '🎪', '🎯'];

  useEffect(() => {
    initializeGame();
  }, []);

  useEffect(() => {
    let timer;
    if (gameStarted && matched.length < symbols.length * 2) {
      timer = setInterval(() => {
        setTime(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [gameStarted, matched.length]);

  const initializeGame = () => {
    const shuffledCards = [...symbols, ...symbols]
      .sort(() => Math.random() - 0.5)
      .map((symbol, index) => ({
        id: index,
        symbol,
        isFlipped: false,
        isMatched: false
      }));
    setCards(shuffledCards);
    setFlipped([]);
    setMatched([]);
    setMoves(0);
    setTime(0);
    setGameStarted(false);
  };

  const handleCardClick = (id) => {
    if (!gameStarted) {
      setGameStarted(true);
    }

    if (flipped.length === 2 || flipped.includes(id) || matched.includes(id)) {
      return;
    }

    const newFlipped = [...flipped, id];
    setFlipped(newFlipped);

    if (newFlipped.length === 2) {
      setMoves(moves + 1);
      const [first, second] = newFlipped;
      if (cards[first].symbol === cards[second].symbol) {
        setMatched([...matched, first, second]);
        setFlipped([]);
      } else {
        setTimeout(() => setFlipped([]), 1000);
      }
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="bg-gray-800/50 backdrop-blur-lg rounded-xl border border-gray-700/50 p-6 w-full max-w-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Timer className="w-5 h-5 text-blue-400" />
            <span className="text-lg font-semibold text-white">{formatTime(time)}</span>
          </div>
          <div className="flex items-center space-x-2">
            <Shuffle className="w-5 h-5 text-purple-400" />
            <span className="text-lg font-semibold text-white">{moves} Moves</span>
          </div>
        </div>
        <button 
          onClick={initializeGame}
          className="flex items-center space-x-2 bg-blue-500/20 text-blue-400 px-4 py-2 rounded-lg hover:bg-blue-500/30 transition-colors"
        >
          <RotateCcw className="w-4 h-4" />
          <span>Reset</span>
        </button>
      </div>

      <div className="grid grid-cols-4 gap-4">
        {cards.map((card) => (
          <button
            key={card.id}
            onClick={() => handleCardClick(card.id)}
            className={`aspect-square text-3xl flex items-center justify-center rounded-xl transition-all duration-300 transform 
              ${flipped.includes(card.id) || matched.includes(card.id)
                ? 'bg-gray-700/50 rotate-0'
                : 'bg-blue-500/20 rotate-180'
              } ${matched.includes(card.id) ? 'opacity-50' : ''}
              hover:scale-105`}
          >
            <span className={flipped.includes(card.id) || matched.includes(card.id) ? '' : 'invisible'}>
              {card.symbol}
            </span>
          </button>
        ))}
      </div>

      {matched.length === symbols.length * 2 && (
        <div className="mt-6 text-center bg-green-500/20 rounded-xl p-6">
          <div className="flex items-center justify-center space-x-2 text-green-400 mb-2">
            <Trophy className="w-8 h-8" />
            <span className="text-2xl font-bold">Congratulations!</span>
          </div>
          <p className="text-gray-300">
            You completed the game in {moves} moves and {formatTime(time)}!
          </p>
        </div>
      )}
    </div>
  );
};

export default MemoryGame;