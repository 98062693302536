import React, { useState, useEffect, useCallback } from 'react';

const BOARD_WIDTH = 10;
const BOARD_HEIGHT = 20;

const TETROMINOS = {
  I: {
    shape: [[1, 1, 1, 1]],
    color: 'bg-cyan-500',
  },
  J: {
    shape: [[1, 0, 0], [1, 1, 1]],
    color: 'bg-blue-500',
  },
  L: {
    shape: [[0, 0, 1], [1, 1, 1]],
    color: 'bg-orange-500',
  },
  O: {
    shape: [[1, 1], [1, 1]],
    color: 'bg-yellow-500',
  },
  S: {
    shape: [[0, 1, 1], [1, 1, 0]],
    color: 'bg-green-500',
  },
  T: {
    shape: [[0, 1, 0], [1, 1, 1]],
    color: 'bg-purple-500',
  },
  Z: {
    shape: [[1, 1, 0], [0, 1, 1]],
    color: 'bg-red-500',
  },
};

const Tetris = () => {
  const [board, setBoard] = useState(createEmptyBoard());
  const [currentPiece, setCurrentPiece] = useState(null);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  function createEmptyBoard() {
    return Array(BOARD_HEIGHT).fill().map(() => Array(BOARD_WIDTH).fill(0));
  }

  const spawnNewPiece = useCallback(() => {
    const pieces = Object.keys(TETROMINOS);
    const newPiece = TETROMINOS[pieces[Math.floor(Math.random() * pieces.length)]];
    setCurrentPiece(newPiece);
    setPosition({ x: Math.floor(BOARD_WIDTH / 2) - 1, y: 0 });
  }, []);

  const moveDown = useCallback(() => {
    if (!currentPiece) return;
    
    if (isValidMove(position.x, position.y + 1)) {
      setPosition(prev => ({ ...prev, y: prev.y + 1 }));
    } else {
      // Lock piece in place
      const newBoard = [...board];
      currentPiece.shape.forEach((row, y) => {
        row.forEach((cell, x) => {
          if (cell) {
            newBoard[y + position.y][x + position.x] = currentPiece.color;
          }
        });
      });
      setBoard(newBoard);
      
      // Check for completed lines
      const completedLines = checkCompletedLines(newBoard);
      if (completedLines > 0) {
        setScore(prev => prev + (completedLines * 100));
      }
      
      // Spawn new piece
      spawnNewPiece();
    }
  }, [currentPiece, position, board]);

  const isValidMove = (newX, newY) => {
    return currentPiece.shape.every((row, y) =>
      row.every((cell, x) =>
        !cell || // empty cell in piece
        (newY + y >= 0 && // within top boundary
         newY + y < BOARD_HEIGHT && // within bottom boundary
         newX + x >= 0 && // within left boundary
         newX + x < BOARD_WIDTH && // within right boundary
         !board[newY + y][newX + x]) // no collision with placed pieces
      )
    );
  };

  const checkCompletedLines = (board) => {
    let completedLines = 0;
    const newBoard = board.filter(row => {
      const isComplete = row.every(cell => cell);
      if (isComplete) completedLines++;
      return !isComplete;
    });
    
    while (newBoard.length < BOARD_HEIGHT) {
      newBoard.unshift(Array(BOARD_WIDTH).fill(0));
    }
    
    setBoard(newBoard);
    return completedLines;
  };

  useEffect(() => {
    if (gameOver) return;
    
    const handleKeyPress = (e) => {
      if (!currentPiece) return;
      
      switch (e.key) {
        case 'ArrowLeft':
          if (isValidMove(position.x - 1, position.y)) {
            setPosition(prev => ({ ...prev, x: prev.x - 1 }));
          }
          break;
        case 'ArrowRight':
          if (isValidMove(position.x + 1, position.y)) {
            setPosition(prev => ({ ...prev, x: prev.x + 1 }));
          }
          break;
        case 'ArrowDown':
          moveDown();
          break;
        case 'ArrowUp':
          // Rotate piece
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentPiece, position, moveDown]);

  useEffect(() => {
    if (gameOver) return;
    
    const gameLoop = setInterval(moveDown, 1000);
    return () => clearInterval(gameLoop);
  }, [moveDown, gameOver]);

  useEffect(() => {
    spawnNewPiece();
  }, []);

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Tetris</h2>
      
      <div className="text-white text-xl mb-4">
        Score: {score}
      </div>

      <div className="border-2 border-gray-700 bg-gray-900">
        {board.map((row, y) => (
          <div key={y} className="flex">
            {row.map((cell, x) => (
              <div
                key={`${x}-${y}`}
                className={`w-6 h-6 border border-gray-800 ${
                  cell || (
                    currentPiece &&
                    y >= position.y &&
                    y < position.y + currentPiece.shape.length &&
                    x >= position.x &&
                    x < position.x + currentPiece.shape[0].length &&
                    currentPiece.shape[y - position.y][x - position.x]
                      ? currentPiece.color
                      : 'bg-gray-900'
                  )
                }`}
              />
            ))}
          </div>
        ))}
      </div>

      {gameOver && (
        <div className="text-center">
          <div className="text-2xl text-white mb-4">
            Game Over! Final Score: {score}
          </div>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-3 bg-cyan-500 text-white rounded-lg hover:bg-cyan-600"
          >
            Play Again
          </button>
        </div>
      )}
    </div>
  );
};

export default Tetris; 