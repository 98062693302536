import React, { useState, useEffect } from 'react';

const ColorMatch = () => {
  const [score, setScore] = useState(0);
  const [currentColor, setCurrentColor] = useState('');
  const [currentWord, setCurrentWord] = useState('');
  const [gameStatus, setGameStatus] = useState('playing');
  const [timeLeft, setTimeLeft] = useState(30);

  const colors = [
    { name: 'RED', hex: '#EF4444' },
    { name: 'BLUE', hex: '#3B82F6' },
    { name: 'GREEN', hex: '#10B981' },
    { name: 'YELLOW', hex: '#F59E0B' },
    { name: 'PURPLE', hex: '#8B5CF6' }
  ];

  const generateNewRound = () => {
    const colorIndex = Math.floor(Math.random() * colors.length);
    const wordIndex = Math.floor(Math.random() * colors.length);
    setCurrentColor(colors[colorIndex].hex);
    setCurrentWord(colors[wordIndex].name);
  };

  useEffect(() => {
    generateNewRound();
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setGameStatus('ended');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleAnswer = (isMatch) => {
    const actualMatch = colors.find(c => c.hex === currentColor)?.name === currentWord;
    
    if (isMatch === actualMatch) {
      setScore(prev => prev + 1);
    } else {
      setScore(prev => Math.max(0, prev - 1));
    }
    
    generateNewRound();
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Color Match</h2>

      <div className="flex justify-between w-full">
        <div className="text-white">Score: {score}</div>
        <div className="text-white">Time: {timeLeft}s</div>
      </div>

      {gameStatus === 'playing' ? (
        <>
          <div 
            className="text-4xl font-bold p-8 rounded-xl"
            style={{ color: currentColor }}
          >
            {currentWord}
          </div>

          <div className="flex space-x-4">
            <button
              onClick={() => handleAnswer(true)}
              className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              Match
            </button>
            <button
              onClick={() => handleAnswer(false)}
              className="px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              No Match
            </button>
          </div>
        </>
      ) : (
        <div className="text-center">
          <div className="text-2xl text-white mb-4">
            Game Over! Final Score: {score}
          </div>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-3 bg-rose-500 text-white rounded-lg hover:bg-rose-600"
          >
            Play Again
          </button>
        </div>
      )}
    </div>
  );
};

export default ColorMatch; 