import React from 'react';
import {
  Gamepad2, Ghost, Swords, Star, Users, Timer, Trophy,
  Flame, Crown, Target, ArrowRight, Rocket, Brain, Dices
} from 'lucide-react';
import MemoryGame from '../games/MemoryGame';
import SnakeGame from '../games/SnakeGame';
import TicTacToe from '../games/TicTacToe';
import WordPuzzle from '../games/WordPuzzle';
import DiceRoll from '../games/DiceRoll';
import MathQuiz from '../games/MathQuiz';
import ColorMatch from '../games/ColorMatch';
import SimonSays from '../games/SimonSays';
import Tetris from '../games/Tetris';
import PianoTiles from '../games/PianoTiles';
import ShootingGallery from '../games/ShootingGallery';
import Puzzle2048 from '../games/Puzzle2048';
import Chess from '../games/Chess';
import BubbleSort from '../games/BubbleSort';
import MazeRunner from '../games/MazeRunner';
import ReactionTime from '../games/ReactionTime';


const GameContainer = ({ currentGame, searchQuery, setCurrentGame }) => {
  const games = [
    {
      id: 'memory',
      name: 'Memory Match',
      tagline: 'Test Your Memory',
      icon: Gamepad2,
      description: 'Challenge your memory skills by matching pairs in this addictive card game',
      color: 'text-purple-400',
      gradient: 'from-purple-500/20 via-purple-400/10 to-transparent',
      stats: { players: '1.2K+', rating: 5.0, time: '5-10m' },
      difficulty: 'Medium',
      category: 'Puzzle'
    },
    {
      id: 'snake',
      name: 'Snake Evolution',
      tagline: 'Classic Reimagined',
      icon: Ghost,
      description: 'Experience the classic Snake game with modern twists and power-ups',
      color: 'text-emerald-400',
      gradient: 'from-emerald-500/20 via-emerald-400/10 to-transparent',
      stats: { players: '2.4K+', rating: 4.9, time: '∞' },
      difficulty: 'Easy',
      category: 'Arcade'
    },
    {
      id: 'tictactoe',
      name: 'Tactical Tic-Tac-Toe',
      tagline: 'Strategic Battles',
      icon: Swords,
      description: 'Challenge friends or AI in this strategic battle of wits',
      color: 'text-blue-400',
      gradient: 'from-blue-500/20 via-blue-400/10 to-transparent',
      stats: { players: '3.1K+', rating: 4.9, time: '2-5m' },
      difficulty: 'Easy',
      category: 'Strategy'
    },
    {
      id: 'tetris',
      name: 'Tetris Classic',
      tagline: 'Block Building Challenge',
      icon: Gamepad2,
      description: 'The classic block-stacking puzzle game that never gets old',
      color: 'text-cyan-400',
      gradient: 'from-cyan-500/20 via-cyan-400/10 to-transparent',
      stats: { players: '2.1K+', rating: 4.8, time: '∞' },
      difficulty: 'Medium',
      category: 'Puzzle'
    },
    {
      id: 'pianotiles',
      name: 'Piano Tiles',
      tagline: 'Rhythm & Speed',
      icon: Star,
      description: 'Test your rhythm and reflexes in this musical challenge',
      color: 'text-indigo-400',
      gradient: 'from-indigo-500/20 via-indigo-400/10 to-transparent',
      stats: { players: '1.5K+', rating: 4.7, time: '3-5m' },
      difficulty: 'Medium',
      category: 'Music'
    },
    {
      id: 'shootinggallery',
      name: 'Shooting Gallery',
      tagline: 'Precision Targeting',
      icon: Target,
      description: 'Test your aim and reflexes in this carnival-style shooting game',
      color: 'text-red-400',
      gradient: 'from-red-500/20 via-red-400/10 to-transparent',
      stats: { players: '900+', rating: 4.6, time: '5-10m' },
      difficulty: 'Medium',
      category: 'Action'
    },
    {
      id: '2048',
      name: 'Puzzle 2048',
      tagline: 'Number Merging Magic',
      icon: Brain,
      description: 'Merge numbers strategically to reach 2048 and beyond',
      color: 'text-yellow-400',
      gradient: 'from-yellow-500/20 via-yellow-400/10 to-transparent',
      stats: { players: '1.8K+', rating: 4.9, time: '∞' },
      difficulty: 'Medium',
      category: 'Puzzle'
    },
    {
      id: 'chess',
      name: 'Chess Master',
      tagline: 'Strategic Warfare',
      icon: Crown,
      description: 'Classic chess with modern features and AI opponents',
      color: 'text-amber-400',
      gradient: 'from-amber-500/20 via-amber-400/10 to-transparent',
      stats: { players: '2.5K+', rating: 4.9, time: '15-30m' },
      difficulty: 'Hard',
      category: 'Strategy'
    },
    {
      id: 'bubblesort',
      name: 'Bubble Sort',
      tagline: 'Algorithm Visualization',
      icon: Brain,
      description: 'Learn sorting algorithms through interactive visualization',
      color: 'text-green-400',
      gradient: 'from-green-500/20 via-green-400/10 to-transparent',
      stats: { players: '600+', rating: 4.5, time: '5-10m' },
      difficulty: 'Medium',
      category: 'Educational'
    },
    {
      id: 'mazerunner',
      name: 'Maze Runner',
      tagline: 'Escape the Labyrinth',
      icon: Target,
      description: 'Navigate through procedurally generated mazes',
      color: 'text-violet-400',
      gradient: 'from-violet-500/20 via-violet-400/10 to-transparent',
      stats: { players: '850+', rating: 4.7, time: '5-15m' },
      difficulty: 'Medium',
      category: 'Puzzle'
    },
    {
      id: 'reactiontime',
      name: 'Reaction Time',
      tagline: 'Test Your Reflexes',
      icon: Timer,
      description: 'Measure and improve your reaction speed',
      color: 'text-orange-400',
      gradient: 'from-orange-500/20 via-orange-400/10 to-transparent',
      stats: { players: '1.1K+', rating: 4.6, time: '1-2m' },
      difficulty: 'Easy',
      category: 'Casual'
    },
    {
      id: 'colormatch',
      name: 'Color Match',
      tagline: 'Visual Memory Challenge',
      icon: Star,
      description: 'Match colors in this fast-paced memory game',
      color: 'text-pink-400',
      gradient: 'from-pink-500/20 via-pink-400/10 to-transparent',
      stats: { players: '750+', rating: 4.5, time: '3-5m' },
      difficulty: 'Easy',
      category: 'Casual'
    },
    {
      id: 'simonsays',
      name: 'Simon Says',
      tagline: 'Pattern Memory',
      icon: Brain,
      description: 'Remember and repeat increasingly complex patterns',
      color: 'text-teal-400',
      gradient: 'from-teal-500/20 via-teal-400/10 to-transparent',
      stats: { players: '950+', rating: 4.7, time: '5-10m' },
      difficulty: 'Medium',
      category: 'Memory'
    },
    {
      id: 'mathquiz',
      name: 'Math Quiz',
      tagline: 'Mental Math Challenge',
      icon: Brain,
      description: 'Test and improve your mental math skills',
      color: 'text-blue-400',
      gradient: 'from-blue-500/20 via-blue-400/10 to-transparent',
      stats: { players: '800+', rating: 4.6, time: '5-10m' },
      difficulty: 'Medium',
      category: 'Educational'
    },
    {
      id: 'wordpuzzle',
      name: 'Word Master',
      tagline: 'Vocabulary Challenge',
      icon: Brain,
      description: 'Expand your vocabulary and test your word skills in this engaging puzzle game',
      color: 'text-yellow-400',
      gradient: 'from-yellow-500/20 via-yellow-400/10 to-transparent',
      stats: { players: '950+', rating: 4.7, time: '5-15m' },
      difficulty: 'Medium',
      category: 'Educational'
    },
    {
      id: 'diceroll',
      name: 'Lucky Dice',
      tagline: 'Roll Your Destiny',
      icon: Dices,
      description: 'Test your luck and strategy in this dice-based probability game',
      color: 'text-pink-400',
      gradient: 'from-pink-500/20 via-pink-400/10 to-transparent',
      stats: { players: '750+', rating: 4.6, time: '2-5m' },
      difficulty: 'Easy',
      category: 'Casual'
    }
  ];

  const filteredGames = searchQuery
    ? games.filter(game =>
      game.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      game.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      game.category.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : games;

  const renderGameSelection = () => (
    <div className="h-[calc(100vh-4rem)] overflow-y-auto
      [&::-webkit-scrollbar]:w-1.5
      [&::-webkit-scrollbar-track]:bg-transparent
      [&::-webkit-scrollbar-thumb]:bg-gray-600/50
      [&::-webkit-scrollbar-thumb]:rounded-full
      [&::-webkit-scrollbar-thumb]:border-2
      [&::-webkit-scrollbar-thumb]:border-transparent
      [&::-webkit-scrollbar-thumb]:bg-clip-padding
      hover:[&::-webkit-scrollbar-thumb]:bg-gray-500/50">
      <div className="p-6 md:p-8">
        <div className="max-w-7xl mx-auto">
          {/* Compact Futuristic Header */}
          <div className="relative mb-8 rounded-xl overflow-hidden">
            {/* Ambient Background */}
            <div className="absolute inset-0 bg-gradient-to-r from-gray-900/80 via-gray-800/80 to-gray-900/80" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(255,165,0,0.1),transparent_50%)]" />

            {/* Decorative Elements */}
            <div className="absolute top-0 left-0 w-24 h-0.5 bg-gradient-to-r from-orange-500 to-transparent" />
            <div className="absolute top-0 right-0 w-24 h-0.5 bg-gradient-to-l from-yellow-500 to-transparent" />

            {/* Corner Accents */}
            <div className="absolute top-0 left-0 w-12 h-12 border-l-2 border-t-2 border-orange-500/30 rounded-tl-xl" />
            <div className="absolute top-0 right-0 w-12 h-12 border-r-2 border-t-2 border-yellow-500/30 rounded-tr-xl" />

            {/* Content */}
            <div className="relative px-6 py-4">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-lg bg-gradient-to-br from-orange-500/20 to-yellow-500/20 
                  border border-orange-500/30 flex items-center justify-center
                  shadow-[0_0_15px_rgba(255,165,0,0.1)]">
                  <Gamepad2 className="w-5 h-5 text-orange-400" />
                </div>
                <div className="h-8 w-0.5 bg-gradient-to-b from-orange-500 to-yellow-500 rounded-full" />
                <div>
                  <h1 className="text-2xl font-bold bg-gradient-to-r from-orange-300 via-yellow-200 to-orange-300 
                    text-transparent bg-clip-text tracking-tight">
                    Gaming Paradise
                  </h1>
                  <p className="text-sm text-gray-400">
                    Your gateway to next-gen entertainment
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Games Grid with futuristic cards */}
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
            {filteredGames.map((game) => (
              <div
                key={game.id}
                onClick={() => setCurrentGame(game.id)}
                className="group relative rounded-xl cursor-pointer overflow-hidden
                  before:absolute before:inset-0 before:rounded-xl before:border before:border-orange-500/20 before:z-20
                  before:transition-all before:duration-700 hover:before:scale-95 hover:before:opacity-0
                  after:absolute after:inset-0 after:rounded-xl after:border after:border-orange-500/20 after:z-20
                  after:transition-all after:duration-700 hover:after:scale-105 hover:after:opacity-0"
              >
                {/* Ambient background effects */}
                <div className={`absolute inset-0 opacity-0 group-hover:opacity-100 
                  transition-all duration-700 bg-gradient-to-br ${game.gradient}`}
                />
                <div className="absolute inset-0 opacity-0 group-hover:opacity-30
                  bg-[radial-gradient(circle_at_50%_50%,rgba(255,165,0,0.1),transparent_70%)]
                  transition-opacity duration-700" />

                {/* Main card container */}
                <div className="relative h-full p-6
                  bg-gray-800/50 backdrop-blur-sm 
                  border border-gray-700/50 rounded-xl
                  group-hover:border-orange-500/20
                  group-hover:shadow-[0_0_30px_rgba(255,165,0,0.1)]
                  transition-all duration-500">

                  {/* Top section */}
                  <div className="flex items-start justify-between mb-4">
                    <div className={`p-3 rounded-xl ${game.color} 
                      shadow-lg group-hover:shadow-orange-500/20
                      transition-all duration-500 group-hover:scale-110`}>
                      <game.icon className={`w-6 h-6 ${game.color}
                        transition-transform duration-500 group-hover:rotate-12`} />
                    </div>
                    <span className="px-3 py-1.5 bg-gray-900/70 rounded-lg text-xs font-medium 
                      text-gray-400 group-hover:text-orange-300 group-hover:bg-gray-900/90
                      transition-all duration-500 group-hover:shadow-[0_0_10px_rgba(255,165,0,0.1)]">
                      {game.difficulty}
                    </span>
                  </div>

                  {/* Game info */}
                  <h3 className="text-xl font-bold text-white mb-2 
                    group-hover:text-transparent group-hover:bg-clip-text
                    group-hover:bg-gradient-to-r group-hover:from-orange-300 group-hover:to-yellow-200
                    transition-all duration-500">{game.name}</h3>
                  <p className="text-sm text-gray-400 mb-3 
                    group-hover:text-gray-300 transition-colors duration-500">{game.tagline}</p>
                  <p className="text-sm text-gray-500 mb-4 
                    group-hover:text-gray-400 transition-colors duration-500">{game.description}</p>

                  {/* Stats section with enhanced styling */}
                  <div className="flex items-center justify-between text-sm
                    p-2 rounded-lg bg-gray-900/50 group-hover:bg-gray-900/70
                    transition-colors duration-500">
                    <div className="flex items-center gap-4">
                      <span className="text-gray-400 group-hover:text-orange-300
                        transition-colors duration-500">
                        {game.stats.players}
                      </span>
                      <div className="flex items-center text-yellow-400 
                        group-hover:text-yellow-300 transition-colors duration-500">
                        <span>{game.stats.rating}</span>
                        <Star className="w-4 h-4 ml-1 fill-current" />
                      </div>
                    </div>
                    <span className="text-gray-400 group-hover:text-orange-300
                      transition-colors duration-500">
                      {game.stats.time}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderActiveGame = () => (
    <div className="h-[calc(100vh-4rem)] flex items-center justify-center p-6">
      <div className="w-full max-w-4xl">
      {currentGame === '2048' && <Puzzle2048 />}
      {currentGame === 'bubblesort' && <BubbleSort />}
      {currentGame === 'chess' && <Chess />}
      {currentGame === 'colormatch' && <ColorMatch />}
      {currentGame === 'diceroll' && <DiceRoll />}
      {currentGame === 'mathquiz' && <MathQuiz />}
      {currentGame === 'mazerunner' && <MazeRunner />}
      {currentGame === 'memory' && <MemoryGame />}
      {currentGame === 'pianotiles' && <PianoTiles />}
      {currentGame === 'reactiontime' && <ReactionTime />}
      {currentGame === 'shootinggallery' && <ShootingGallery />}
      {currentGame === 'simonsays' && <SimonSays />}
      {currentGame === 'snake' && <SnakeGame />}
      {currentGame === 'tetris' && <Tetris />}
      {currentGame === 'tictactoe' && <TicTacToe />}
      {currentGame === 'wordpuzzle' && <WordPuzzle />}
      </div>
    </div>
  );

  return (
    <div className="h-[calc(100vh-4rem)] bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 fixed w-full lg:w-[calc(100%-16rem)] overflow-hidden">
      {currentGame ? renderActiveGame() : renderGameSelection()}
    </div>
  );
};

export default GameContainer;
