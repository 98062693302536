import React, { useState, useEffect } from 'react';

const ShootingGallery = () => {
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(30);
  const [targets, setTargets] = useState([]);
  const [gameStatus, setGameStatus] = useState('waiting'); // waiting, playing, ended

  const createTarget = () => ({
    id: Math.random(),
    x: Math.random() * 80 + 10, // 10-90% of width
    y: Math.random() * 80 + 10, // 10-90% of height
    size: Math.random() * 20 + 20, // 20-40px
    speed: Math.random() * 2 + 1, // 1-3
    direction: Math.random() * Math.PI * 2, // 0-2π
  });

  const startGame = () => {
    setScore(0);
    setTimeLeft(30);
    setTargets(Array(5).fill(null).map(createTarget));
    setGameStatus('playing');
  };

  useEffect(() => {
    if (gameStatus !== 'playing') return;

    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          setGameStatus('ended');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [gameStatus]);

  useEffect(() => {
    if (gameStatus !== 'playing') return;

    const moveTargets = setInterval(() => {
      setTargets(prevTargets => 
        prevTargets.map(target => {
          let newX = target.x + Math.cos(target.direction) * target.speed;
          let newY = target.y + Math.sin(target.direction) * target.speed;
          
          // Bounce off walls
          if (newX < 0 || newX > 100) {
            target.direction = Math.PI - target.direction;
            newX = target.x;
          }
          if (newY < 0 || newY > 100) {
            target.direction = -target.direction;
            newY = target.y;
          }
          
          return { ...target, x: newX, y: newY };
        })
      );
    }, 16);

    return () => clearInterval(moveTargets);
  }, [gameStatus]);

  const handleTargetClick = (targetId) => {
    if (gameStatus !== 'playing') return;

    setScore(prev => prev + 1);
    setTargets(prevTargets => 
      prevTargets.map(target => 
        target.id === targetId ? createTarget() : target
      )
    );
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Shooting Gallery</h2>
      
      <div className="flex justify-between w-full max-w-md">
        <div className="text-white text-xl">Score: {score}</div>
        <div className="text-white text-xl">Time: {timeLeft}s</div>
      </div>

      {gameStatus === 'playing' ? (
        <div className="relative w-[600px] h-[400px] bg-gray-900 rounded-xl overflow-hidden cursor-crosshair">
          {targets.map(target => (
            <button
              key={target.id}
              onClick={() => handleTargetClick(target.id)}
              style={{
                left: `${target.x}%`,
                top: `${target.y}%`,
                width: `${target.size}px`,
                height: `${target.size}px`,
                transform: 'translate(-50%, -50%)',
              }}
              className="absolute rounded-full bg-orange-500 hover:bg-orange-400 
                       transition-colors duration-100"
            />
          ))}
        </div>
      ) : (
        <div className="text-center">
          {gameStatus === 'waiting' ? (
            <button
              onClick={startGame}
              className="px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600"
            >
              Start Game
            </button>
          ) : (
            <div>
              <div className="text-2xl text-white mb-4">
                Game Over! Final Score: {score}
              </div>
              <button
                onClick={startGame}
                className="px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600"
              >
                Play Again
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ShootingGallery; 