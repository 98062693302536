import React, { useState, useEffect } from 'react';

const BubbleSort = () => {
  const [array, setArray] = useState([]);
  const [sorting, setSorting] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [comparisons, setComparisons] = useState(0);
  const [swaps, setSwaps] = useState(0);
  const [speed, setSpeed] = useState(500);

  const generateArray = () => {
    const newArray = Array(10).fill(0).map(() => 
      Math.floor(Math.random() * 50) + 1
    );
    setArray(newArray);
    setCurrentStep(0);
    setComparisons(0);
    setSwaps(0);
    setSorting(false);
  };

  useEffect(() => {
    generateArray();
  }, []);

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const bubbleSort = async () => {
    setSorting(true);
    const n = array.length;
    let newArray = [...array];
    
    for (let i = 0; i < n - 1; i++) {
      for (let j = 0; j < n - i - 1; j++) {
        if (!sorting) return; // Allow stopping the sort
        
        setCurrentStep(j);
        setComparisons(prev => prev + 1);
        await sleep(speed);

        if (newArray[j] > newArray[j + 1]) {
          // Swap elements
          [newArray[j], newArray[j + 1]] = [newArray[j + 1], newArray[j]];
          setArray([...newArray]);
          setSwaps(prev => prev + 1);
        }
      }
    }

    setCurrentStep(-1);
    setSorting(false);
  };

  const getBarColor = (index) => {
    if (index === currentStep || index === currentStep + 1) {
      return 'bg-fuchsia-500';
    }
    return 'bg-fuchsia-300';
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-4 sm:p-8 bg-gray-800 rounded-xl w-full max-w-2xl mx-auto">
      <h2 className="text-xl sm:text-2xl font-bold text-white">Bubble Sort</h2>
      
      {/* Stats */}
      <div className="flex flex-col sm:flex-row justify-between w-full gap-2 sm:gap-4">
        <div className="text-white text-sm sm:text-base">Comparisons: {comparisons}</div>
        <div className="text-white text-sm sm:text-base">Swaps: {swaps}</div>
      </div>

      {/* Visualization */}
      <div className="flex items-end justify-center w-full h-48 sm:h-64 gap-1 sm:gap-2 bg-gray-700 p-4 rounded-xl">
        {array.map((value, index) => (
          <div
            key={index}
            className={`w-6 sm:w-8 ${getBarColor(index)} transition-all duration-200`}
            style={{ height: `${value * 2}%` }}
          >
            <div className="text-center text-white text-xs sm:text-sm">{value}</div>
          </div>
        ))}
      </div>

      {/* Controls */}
      <div className="flex flex-wrap justify-center gap-2 sm:gap-4">
        <button
          onClick={generateArray}
          disabled={sorting}
          className="px-3 py-2 sm:px-4 sm:py-2 text-sm sm:text-base bg-gray-600 text-white rounded-lg 
                   hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          New Array
        </button>
        <button
          onClick={sorting ? () => setSorting(false) : bubbleSort}
          className={`px-3 py-2 sm:px-4 sm:py-2 text-sm sm:text-base rounded-lg text-white
            ${sorting ? 'bg-red-500 hover:bg-red-600' : 'bg-fuchsia-500 hover:bg-fuchsia-600'}`}
        >
          {sorting ? 'Stop' : 'Sort'}
        </button>
        <select
          value={speed}
          onChange={(e) => setSpeed(Number(e.target.value))}
          className="px-3 py-2 sm:px-4 sm:py-2 text-sm sm:text-base bg-gray-600 text-white rounded-lg"
        >
          <option value={1000}>Slow</option>
          <option value={500}>Medium</option>
          <option value={200}>Fast</option>
        </select>
      </div>

      {/* Algorithm explanation */}
      <div className="text-gray-300 text-xs sm:text-sm max-w-md text-center px-4">
        Bubble Sort repeatedly steps through the list, compares adjacent elements 
        and swaps them if they are in the wrong order. The pass through the list 
        is repeated until no swaps are needed.
      </div>
    </div>
  );
};

export default BubbleSort; 