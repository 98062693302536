import React, { useState, useEffect } from 'react';

const MathQuiz = () => {
  const [score, setScore] = useState(0);
  const [question, setQuestion] = useState({});
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState('');
  const [timeLeft, setTimeLeft] = useState(60);

  const generateQuestion = () => {
    const operations = ['+', '-', '*'];
    const operation = operations[Math.floor(Math.random() * operations.length)];
    let num1 = Math.floor(Math.random() * 12) + 1;
    let num2 = Math.floor(Math.random() * 12) + 1;
    
    let answer;
    switch (operation) {
      case '+': answer = num1 + num2; break;
      case '-': answer = num1 - num2; break;
      case '*': answer = num1 * num2; break;
      default: answer = 0;
    }

    return { num1, num2, operation, answer };
  };

  useEffect(() => {
    setQuestion(generateQuestion());
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isCorrect = parseInt(userAnswer) === question.answer;
    
    setFeedback(isCorrect ? 'Correct!' : 'Wrong!');
    setScore(prev => isCorrect ? prev + 1 : prev);
    setUserAnswer('');
    
    setTimeout(() => {
      setFeedback('');
      setQuestion(generateQuestion());
    }, 1000);
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Math Quiz</h2>
      
      <div className="flex justify-between w-full">
        <div className="text-white">Score: {score}</div>
        <div className="text-white">Time: {timeLeft}s</div>
      </div>

      {timeLeft > 0 ? (
        <>
          <div className="text-3xl text-white">
            {question.num1} {question.operation} {question.num2} = ?
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="number"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              className="px-4 py-2 rounded-lg bg-gray-700 text-white"
              placeholder="Enter your answer"
              autoFocus
            />
            <button
              type="submit"
              className="w-full px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600"
            >
              Submit
            </button>
          </form>

          {feedback && (
            <div className={`text-xl ${feedback === 'Correct!' ? 'text-green-500' : 'text-red-500'}`}>
              {feedback}
            </div>
          )}
        </>
      ) : (
        <div className="text-2xl text-white">
          Game Over! Final Score: {score}
          <button
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600"
          >
            Play Again
          </button>
        </div>
      )}
    </div>
  );
};

export default MathQuiz; 