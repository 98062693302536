import React, { useState, useEffect, useRef } from 'react';
import { Zap } from 'lucide-react';

const ReactionTime = () => {
  const [gameState, setGameState] = useState('waiting'); // waiting, ready, clicking, results
  const [startTime, setStartTime] = useState(null);
  const [reactionTime, setReactionTime] = useState(null);
  const [bestTime, setBestTime] = useState(null);
  const [lastFiveTimes, setLastFiveTimes] = useState([]);
  const timeoutRef = useRef(null);

  const startGame = () => {
    setGameState('ready');
    // Random delay between 1-5 seconds
    const delay = Math.random() * 4000 + 1000;
    timeoutRef.current = setTimeout(() => {
      setStartTime(Date.now());
      setGameState('clicking');
    }, delay);
  };

  const handleClick = () => {
    switch (gameState) {
      case 'waiting':
        startGame();
        break;
      
      case 'ready':
        // Clicked too early
        clearTimeout(timeoutRef.current);
        setGameState('failed');
        break;
      
      case 'clicking':
        const endTime = Date.now();
        const time = endTime - startTime;
        setReactionTime(time);
        
        // Update best time
        if (!bestTime || time < bestTime) {
          setBestTime(time);
        }
        
        // Update last five times
        setLastFiveTimes(prev => {
          const newTimes = [time, ...prev.slice(0, 4)];
          return newTimes;
        });
        
        setGameState('results');
        break;
      
      case 'failed':
      case 'results':
        startGame();
        break;
      
      default:
        break;
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const getBackgroundColor = () => {
    switch (gameState) {
      case 'waiting': return 'bg-yellow-500';
      case 'ready': return 'bg-red-500';
      case 'clicking': return 'bg-green-500';
      case 'failed': return 'bg-gray-500';
      case 'results': return 'bg-blue-500';
      default: return 'bg-gray-500';
    }
  };

  const getMessage = () => {
    switch (gameState) {
      case 'waiting': return 'Click to start';
      case 'ready': return 'Wait for green...';
      case 'clicking': return 'Click now!';
      case 'failed': return 'Too early! Click to try again';
      case 'results': return `${reactionTime}ms - Click to try again`;
      default: return '';
    }
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Lightning Reflex</h2>

      {/* Main game area */}
      <button
        onClick={handleClick}
        className={`w-64 h-64 rounded-xl flex flex-col items-center justify-center 
          transition-colors duration-200 ${getBackgroundColor()}`}
      >
        <Zap className="w-16 h-16 text-white mb-4" />
        <span className="text-white text-xl font-bold">{getMessage()}</span>
      </button>

      {/* Stats */}
      <div className="grid grid-cols-2 gap-4 w-64">
        {bestTime && (
          <div className="bg-gray-700 p-4 rounded-lg text-center">
            <div className="text-gray-400 text-sm">Best Time</div>
            <div className="text-white font-bold">{bestTime}ms</div>
          </div>
        )}
        
        {lastFiveTimes.length > 0 && (
          <div className="bg-gray-700 p-4 rounded-lg text-center">
            <div className="text-gray-400 text-sm">Average</div>
            <div className="text-white font-bold">
              {Math.round(lastFiveTimes.reduce((a, b) => a + b, 0) / lastFiveTimes.length)}ms
            </div>
          </div>
        )}
      </div>

      {/* Last 5 attempts */}
      {lastFiveTimes.length > 0 && (
        <div className="w-64">
          <div className="text-gray-400 mb-2">Last 5 attempts:</div>
          <div className="grid grid-cols-5 gap-2">
            {lastFiveTimes.map((time, index) => (
              <div
                key={index}
                className="bg-gray-700 p-2 rounded text-center text-white text-sm"
              >
                {time}ms
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReactionTime; 