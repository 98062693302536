import React, { useState, useEffect } from 'react';

const SimonSays = () => {
  const [sequence, setSequence] = useState([]);
  const [playerSequence, setPlayerSequence] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [score, setScore] = useState(0);
  const [gameStatus, setGameStatus] = useState('waiting'); // waiting, showing, input, gameOver

  const colors = [
    { id: 0, color: 'bg-red-500', activeColor: 'bg-red-300' },
    { id: 1, color: 'bg-blue-500', activeColor: 'bg-blue-300' },
    { id: 2, color: 'bg-green-500', activeColor: 'bg-green-300' },
    { id: 3, color: 'bg-yellow-500', activeColor: 'bg-yellow-300' }
  ];

  const startGame = () => {
    setSequence([Math.floor(Math.random() * 4)]);
    setPlayerSequence([]);
    setScore(0);
    setIsPlaying(true);
    setGameStatus('showing');
  };

  const showSequence = async () => {
    setGameStatus('showing');
    for (let i = 0; i < sequence.length; i++) {
      await new Promise(resolve => setTimeout(resolve, 500));
      const button = document.getElementById(`button-${sequence[i]}`);
      button.classList.remove(colors[sequence[i]].color);
      button.classList.add(colors[sequence[i]].activeColor);
      await new Promise(resolve => setTimeout(resolve, 500));
      button.classList.remove(colors[sequence[i]].activeColor);
      button.classList.add(colors[sequence[i]].color);
    }
    setGameStatus('input');
  };

  useEffect(() => {
    if (gameStatus === 'showing') {
      showSequence();
    }
  }, [sequence, gameStatus]);

  const handleButtonClick = (colorId) => {
    if (gameStatus !== 'input') return;

    const newPlayerSequence = [...playerSequence, colorId];
    setPlayerSequence(newPlayerSequence);

    if (newPlayerSequence[newPlayerSequence.length - 1] !== sequence[newPlayerSequence.length - 1]) {
      setGameStatus('gameOver');
      return;
    }

    if (newPlayerSequence.length === sequence.length) {
      setScore(prev => prev + 1);
      setPlayerSequence([]);
      setTimeout(() => {
        setSequence([...sequence, Math.floor(Math.random() * 4)]);
        setGameStatus('showing');
      }, 1000);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-6 p-8 bg-gray-800 rounded-xl">
      <h2 className="text-2xl font-bold text-white">Simon Says</h2>
      
      <div className="text-white text-xl mb-4">
        Score: {score}
      </div>

      <div className="grid grid-cols-2 gap-4">
        {colors.map(({ id, color }) => (
          <button
            key={id}
            id={`button-${id}`}
            onClick={() => handleButtonClick(id)}
            className={`w-32 h-32 ${color} rounded-lg transition-colors duration-200`}
            disabled={gameStatus !== 'input'}
          />
        ))}
      </div>

      {gameStatus === 'waiting' && (
        <button
          onClick={startGame}
          className="px-6 py-3 bg-amber-500 text-white rounded-lg hover:bg-amber-600"
        >
          Start Game
        </button>
      )}

      {gameStatus === 'gameOver' && (
        <div className="text-center">
          <div className="text-2xl text-white mb-4">
            Game Over! Final Score: {score}
          </div>
          <button
            onClick={startGame}
            className="px-6 py-3 bg-amber-500 text-white rounded-lg hover:bg-amber-600"
          >
            Play Again
          </button>
        </div>
      )}
    </div>
  );
};

export default SimonSays; 